/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from "axios";
import LCFQheader from "../LCFQheader";
import TablePage from "../../common/sbsqRadio/TablePage";
import { RxUtil } from "@/assets/util.js";
import { timesFnt, convertKey, format, unique, convertCurrency } from "@/assets/app.js";
export default {
  name: "lcfqHksq",
  components: {
    LCFQheader,
    TablePage
  },
  data() {
    return {
      oneft: "/index",
      headTit: "新建申请",
      tit: "设备报废、停用、降级申请",
      //下拉
      showHKDW: false,
      showSqlx: false,
      columnsHKDW: [],
      columnSqlx: [],
      showSqrq: false,
      showGzrq: false,
      showListpage: false,
      minDate: new Date(2020, 0, 1),
      currentDate: new Date(),
      showGinfo: false,
      //userSelect
      single: false,
      allowSelect: true,
      showUserDialog: false,
      userID: "",
      solId: "",
      boDefId: "",
      formKey: "",
      actDefId: "",
      resultFa: [],
      wpInfoData: {},
      data: {
        xz_sb_tysq_sqr_name: "",
        xz_sb_tysq_jdyj: "",
        xz_sb_tysq_jzfzrryj: "",
        xz_sb_tysq_sbbh: "",
        xz_sb_tysq_sbmc: "",
        xz_sb_tysq_sbmc_name: "",
        xz_sb_tysq_sqbm: "",
        xz_sb_tysq_sqlb: "",
        xz_sb_tysq_sqlb_name: "",
        xz_sb_tysq_sqly: "",
        xz_sb_tysq_sqsj: "",
        xz_sb_tysq_yynx: "",
        xz_sb_tysq_zxzr: ""
      },
      xz_sb_tysq_sqlb: "",
      zffs: "",
      jedx: ""
    };
  },
  created() {
    this.initBySolInstId();
    this.cgORxj();
  },
  mounted() {
    this.getPopup();
  },
  methods: {
    initBySolInstId() {
      let solId = this.$route.params.solId;
      var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId;
      this.$ajax.post(url).then(response => {
        this.solId = response.data.bpmSolution.solId;
        this.actDefId = response.data.bpmSolution.actDefId;
        this.boDefId = response.data.formModels[0].boDefId;
        this.formKey = response.data.formModels[0].formKey;
        // let xz_sb_tysq_sqr_name = [{id:response.data.formModels[0].jsonData.xz_sb_tysq_sqr,text:response.data.formModels[0].jsonData.xz_sb_tysq_sqr_name}];
        // this.data.xz_sb_tysq_sqr_name = JSON.stringify(xz_sb_tysq_sqr_name);
      });
    },

    async cgORxj() {
      //let busad = this.$route.params.busad;
      let instId = this.$route.params.instId;
      if (instId != 0) {
        //if (typeof(busad) != "undefined"){
        this.headTit = "我的草稿";
        this.oneft = "/myDrafts";
        /**测试接口**/
        var user_No = RxUtil.getCache("userNo"); //这里要重新储存
        console.log(user_No);
        var url2 = _baseUrl + "/mobile/bpm/getFormDataByInstId.do?instId=" + instId + "&userAccount=" + user_No;
        var this_ = this;
        this.$ajax.post(url2).then(response => {
          var resData = response.data.data.bos[0].data;
          console.log("草稿数据返填=", resData);
          let xz_sb_tysq_sqr_name = [{
            id: resData.xz_sb_tysq_sqr,
            text: resData.xz_sb_tysq_sqr_name
          }];
          this_.data.xz_sb_tysq_sqr_name = JSON.stringify(xz_sb_tysq_sqr_name);
          this_.data.xz_sb_tysq_sqsj = resData.xz_sb_tysq_sqsj;
          this_.data.xz_sb_tysq_sbmc = resData.xz_sb_tysq_sbmc;
          this_.data.xz_sb_tysq_sbmc_name = resData.xz_sb_tysq_sbmc_name;
          this_.data.xz_sb_tysq_jdyj = resData.xz_sb_tysq_jdyj;
          this_.data.xz_sb_tysq_jzfzrryj = resData.xz_sb_tysq_jzfzrryj;
          this_.data.xz_sb_tysq_sbbh = resData.xz_sb_tysq_sbbh;
          this_.data.xz_sb_tysq_sqbm = resData.xz_sb_tysq_sqbm;
          this_.data.xz_sb_tysq_sqlb = resData.xz_sb_tysq_sqlb;
          this_.data.xz_sb_tysq_sqlb_name = resData.xz_sb_tysq_sqlb_name;
          this_.data.xz_sb_tysq_sqly = resData.xz_sb_tysq_sqly;
          this_.data.xz_sb_tysq_yynx = resData.xz_sb_tysq_yynx;
          this_.data.xz_sb_tysq_zxzr = resData.xz_sb_tysq_zxzr;
          this_.data.xz_sb_tysq_gzrq = resData.xz_sb_tysq_gzrq;
        });

        //     var url = _baseUrl + "/customizeProc/purRequisition/purRequisition/getJson.do?ids="+busad;
        // this.$ajax.post(url).then(res=>{
        // 	this.data = res.data;
        //       let urlUser1 = _baseUrl + `/mobile/oa/getUsersSearch.do?pageIndex=0&pageSize=20&searchVal=${dlrseach}`;
        //       this.$ajax.post(urlUser1).then(resdlr=>{
        //       let dlr = [{id:resdlr.data.rowList[0].userId,text:resdlr.data.rowList[0].fullname}];
        //         this.data.dlrName = JSON.stringify(dlr);
        //       })
        // });
      } else {
        this.getUser();
      }
    },
    //获取用户信息
    getUser() {
      this.data.cgsqr = RxUtil.getCache("userName");
    },
    getPopup() {
      let urlhthi = _baseUrl + "/sys/core/sysDic/listByKey.do?key=SBBFSQLB&_=1629786043548";
      this.$ajax.post(urlhthi).then(res => {
        for (let i = 0; i < res.data.length; i++) {
          this.columnSqlx.push({
            'text': res.data[i].name,
            'key': res.data[i].key
          });
        }
      });
    },
    onConfiSqlx(value) {
      this.data.xz_sb_tysq_sqlb_name = value.text;
      this.xz_sb_tysq_sqlb = value.key;
      this.showSqlx = false;
    },
    dayTime(dataTime) {
      let year = dataTime.getFullYear();
      const month = (dataTime.getMonth() + 1).toString().padStart(2, "0");
      const date = dataTime.getDate().toString().padStart(2, "0") + " ";
      const hours = dataTime.getHours() + ":";
      const minutes = dataTime.getMinutes();
      return year + "-" + month + "-" + date;
    },
    onConfirmSqrq(timestamp) {
      this.data.xz_sb_tysq_sqsj = this.dayTime(timestamp);
      this.showSqrq = false;
    },
    onConfiemGzrq(timestamp) {
      this.data.xz_sb_tysq_gzrq = this.dayTime(timestamp);
      this.showGzrq = false;
    },
    //选代理人
    selectUserDialog(vm) {
      this.showUserDialog = true;
      this.$refs.sysUser.search();
      // 设置选择用户。
      this.$refs.sysUser.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeUserVm = vm;
    },
    selectUser(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        o.id = obj.userId;
        o.text = obj.fullname;
        list.push(o);
      }
      var json = JSON.stringify(list);
      console.log(json);
      this.nodeUserVm.setValue(json);
      this.showUserDialog = false;
    },
    showList() {
      this.showListpage = true;
    },
    tdObj(item) {
      console.log(item);
      this.data.xz_sb_tysq_sqbm = item.FZDD;
      this.data.xz_sb_tysq_sbmc_name = item.MC;
      this.data.xz_sb_tysq_sbbh = item.SBBH;
      this.showListpage = false;
    },
    onJedx() {
      this.jedx = convertCurrency(this.data.je);
    },
    datas() {
      let sqr = JSON.parse(this.data.xz_sb_tysq_sqr_name);

      // console.log(sqr);
      // console.log(sqr[0].id,sqr[0].text);
      var jsonDataObj = {
        bos: [{
          boDefId: this.boDefId,
          formKey: this.formKey,
          data: {
            xz_sb_tysq_sqr: sqr[0].id,
            xz_sb_tysq_sqr_name: sqr[0].text,
            xz_sb_tysq_sqsj: this.data.xz_sb_tysq_sqsj,
            xz_sb_tysq_sbmc: this.data.xz_sb_tysq_sbmc_name,
            xz_sb_tysq_sbmc_name: this.data.xz_sb_tysq_sbmc_name,
            xz_sb_tysq_sbbh: this.data.xz_sb_tysq_sbbh,
            xz_sb_tysq_gzrq: this.data.xz_sb_tysq_gzrq,
            xz_sb_tysq_yynx: this.data.xz_sb_tysq_yynx,
            xz_sb_tysq_sqlb: this.xz_sb_tysq_sqlb,
            xz_sb_tysq_sqlb_name: this.data.xz_sb_tysq_sqlb_name,
            xz_sb_tysq_sqbm: this.data.xz_sb_tysq_sqbm,
            xz_sb_tysq_sqly: this.data.xz_sb_tysq_sqly,
            xz_sb_tysq_jdyj: this.data.xz_sb_tysq_jdyj,
            xz_sb_tysq_jzfzrryj: this.data.xz_sb_tysq_jzfzrryj,
            xz_sb_tysq_zxzr: this.data.xz_sb_tysq_zxzr
          }
        }]
      };
      var jsonData = JSON.stringify(jsonDataObj);
      var params = new FormData();
      params.append("solId", this.solId);
      params.append("bpmInstId", "");
      params.append("jsonData", jsonData);
      return params;
    },
    //字段检查
    iN(str) {
      if (str == '' || str == null || typeof str == 'undefined') {
        return true;
      }
      return false;
    },
    //整体判断
    inspect() {
      if (this.iN(this.data.xz_sb_tysq_sqr_name) || this.iN(this.data.xz_sb_tysq_sbmc_name)) {
        return true;
      }
      return false;
    },
    onSubmit() {
      if (this.inspect()) {
        this.errorToast("启动失败！请检查信息是否填写完整", "1800");
        return;
      }
      //if(this.data.je != ""){
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/startProcess.do";
      this.$dialog.loading.open('正在启动流程,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("启动成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败！" + data.data, "1800");
        }
      });
      // }else{
      //   this.errorToast("金额不能为空！");
      // }
    },

    onPreservation() {
      if (this.inspect()) {
        this.errorToast("保存失败！请检查信息是否填写完整", "1800");
        return;
      }
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/saveDraft.do";
      this.$dialog.loading.open('正在保存草稿,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("保存成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("保存失败！" + data.data, "1800");
        }
      });
    },
    onLct() {
      this.$router.push({
        name: "procImage",
        params: {
          id: this.actDefId,
          type: 'start'
        }
      });
    }
  }
};